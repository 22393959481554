
/* Andrew: put this where ever horse item css is */

.trip-grade{

	font-size: .6rem;
	z-index: 6;
	color: black;
	position: absolute;
	top: 0px;
	right: 0px;
	font-weight: 800;
	margin-top: 5px; margin-right: 5px;
	padding:  0px 3px 0px 3px;
	border-radius: 15px;
}

.trip-grade.up {
	background-color: aquamarine;
}
.trip-grade.down {
	background-color: lightpink;
}

.trip-grade.excused {
	background-color: lightblue;
}



.w-checkbox {
  display: block;
  margin-bottom: 5px;
  padding-left: 20px;
}

.w-checkbox::before {
  content: ' ';
  display: table;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
}

.w-checkbox::after {
  content: ' ';
  display: table;
  -ms-grid-column-span: 1;
  grid-column-end: 2;
  -ms-grid-column: 1;
  grid-column-start: 1;
  -ms-grid-row-span: 1;
  grid-row-end: 2;
  -ms-grid-row: 1;
  grid-row-start: 1;
  clear: both;
}

.w-checkbox-input {
  float: left;
  margin-bottom: 0px;
  margin-left: -20px;
  margin-right: 0px;
  margin-top: 4px;
  line-height: normal;
}

.w-checkbox-input--inputType-custom {
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  border-top-color: #ccc;
  border-bottom-color: #ccc;
  border-left-color: #ccc;
  border-right-color: #ccc;
  border-top-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-right-style: solid;
  width: 12px;
  height: 12px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.w-checkbox-input--inputType-custom.w--redirected-checked {
  background-color: #3898ec;
  border-top-color: #3898ec;
  border-bottom-color: #3898ec;
  border-left-color: #3898ec;
  border-right-color: #3898ec;
  background-image: url('https://d3e54v103j8qbb.cloudfront.net/static/custom-checkbox-checkmark.589d534424.svg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.w-checkbox-input--inputType-custom.w--redirected-focus {
  box-shadow: 0px 0px 3px 1px #3898ec;
}

body {
  font-family: Ilisarniq, sans-serif;
  color: #333;
  font-size: 14px;
  line-height: 20px;
}

h1 {
  margin-top: 0px;
  margin-bottom: 10px;
  font-family: 'Playfair Display', sans-serif;
  color: #fff;
  font-size: 100px;
  line-height: 95px;
  font-weight: 700;
  text-transform: uppercase;
}

a {
  text-decoration: underline;
  cursor: pointer;
}

.main-wrapper {
  overflow: hidden;
  width: 100%;
  height: 100vh;
  background-color: #000;
}

.navbar {
  padding-top: 15px;
  padding-right: 20px;
  padding-left: 20px;
  border-bottom: 1px solid #292a30;
  background-color: #000;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.navbar.home {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  z-index: 50;
  padding: 0px;
  border-bottom-style: none;
  background-color: #000;
}

.navbar.pricing {
  padding-top: 30px;
  padding-right: 15px;
  padding-left: 15px;
  border-bottom-style: none;
}

.nav-link {
  height: 100%;
  margin-right: 24px;
  margin-left: 24px;
  padding: 0px;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  color: #b5b8bf;
  font-size: 13px;
  line-height: 16px;
  font-weight: 700;
  text-transform: uppercase;
}

.nav-link:hover {
  color: #fff;
}

.nav-link.w--current {
  border-bottom-color: #39d377;
  color: #39d377;
}

.nav-inner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.profile-block.mobile {
  display: none;
}

.profile-img {
  display: none;
  overflow: hidden;
  width: 100%;
  height: 36px;
  max-width: 36px;
  min-width: 36px;
  margin-right: 10px;
  border: 2px solid #42d376;
  border-radius: 100%;
}

.dropdown-toggle {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 0px 20px 0px 0px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: #b5b8bf;
  font-size: 13px;
  line-height: 16px;
  font-weight: 700;
  text-transform: uppercase;
}

.profile-arrow {
  margin-right: 0px;
  color: #39d377;
  font-size: 16px;
  line-height: 1;
}

.nav-menu.home {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: auto;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.content-wrapper {
  overflow: auto;
  height: 100%;
  padding-top: 7px;
  padding-right: 20px;
  padding-left: 20px;
}

.content-head {
  margin-bottom: 16px;
  text-align: center;
}

.content-head-text {
  display: inline-block;
  margin: 0px auto;
  padding: 10px 22px;
  border-radius: 50px;
  background-color: #121212;
  color: #42d376;
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  text-transform: uppercase;
}

.date-text {
  display: inline-block;
  margin-left: 6px;
  color: #fff;
  font-size: 18px;
}

.date-text.sm {
  color: #dfdfdf;
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
}

.content-body {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.content-leftside {
  overflow: hidden;
  width: 100%;
}

.content-rightbar {
  width: 100%;
  max-width: 480px;
  min-width: 480px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 480px;
  -ms-flex: 0 480px;
  flex: 0 480px;
  background-color: #121212;
}

.rightbar-head-block {
  padding: 18px 25px;
  background-color: #0102f6;
  color: #fff;
  font-size: 24px;
  line-height: 30px;
  font-weight: 700;
}

.rightbar-head-text {
  margin-top: 0px;
  margin-bottom: 0px;
}

.rightbar-content {
  padding: 16px 16px 6px;
}

.trip-info-block {
  margin-bottom: 16px;
  padding: 12px 20px 16px;
  background-color: #060606;
}

.trip-title-block {
  position: relative;
  margin-bottom: 16px;
  padding-bottom: 4px;
  border-bottom: 1px solid #39d377;
}

.border-blk {
  position: absolute;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: 0%;
  display: none;
  width: 3px;
  height: 100%;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  background-color: #fff;
}

.trip-title {
  display: inline-block;
  margin-top: 0px;
  margin-bottom: 0px;
  color: #39d377;
  line-height: 30px;
  font-weight: 600;
  text-transform: uppercase;
}

.dis-surface-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: 60%;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.dis-surface-block.left {
  min-width: 40%;
  margin-right: 20px;
}

.trip-sub-title {
  display: inline-block;
  margin-top: 0px;
  margin-bottom: 0px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
}

.surface-type {
  display: inline-block;
  margin-bottom: 4px;
  margin-left: 8px;
  padding: 1px 12px;
  border: 1px solid #9b7653;
  border-radius: 23px;
  background-color: rgba(155, 118, 83, 0.15);
  color: #9b7653;
  font-size: 16px;
  line-height: 26px;
  font-weight: 600;
}

.surface-type.green {
  border-color: #42d376;
  background-color: rgba(66, 211, 118, 0.15);
  color: #42d376;
}

.surface-type.blue {
  border-color: #76eaff;
  background-color: rgba(118, 234, 255, 0.15);
  color: #76eaff;
}

.distance-wp {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.surface-type-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.trip-para {
  margin-bottom: 0px;
  color: #CCCCCC;
  font-size: 14px;
  font-weight: 500;
}

.summary-type-wp {
  margin-top: -10px;
}

.summary-block {
  display: inline-block;
  margin-top: 10px;
  margin-right: 10px;
  padding: 6px 16px;
  border-style: solid;
  border-width: 1px;
  border-color: hsla(0, 0%, 100%, 0.3);
  border-radius: 36px;
  color: #dfdfdf;
  font-size: 13px;
  line-height: 16px;
  font-weight: 600;
}

.rightbar-button {
  padding: 6px 16px 24px;
  background-color: #121212;
  text-align: center;
}

.btn {
  display: inline-block;
  width: 100%;
  max-width: 164px;
  margin-right: 10px;
  margin-left: 10px;
  padding-top: 21px;
  padding-bottom: 21px;
  border-style: solid;
  border-width: 1px;
  border-color: #fff;
  border-radius: 30px;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  color: #fff;
  font-size: 16px;
  line-height: 1;
  font-weight: 700;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
}

.btn.primary {
  border-color: #42d376;
  background-color: #39d377;
  color: #000;
}

.btn.secondary {
  border-color: #979797;
  background-color: transparent;
}

.race-tab-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: auto;
  padding: 15px 10px;
  background-color: #181818;
}

.racing-tab {
  display: inline-block;
  margin-right: 10px;
  padding: 11px 20px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border: 1px solid #484848;
  border-radius: 38px;
  background-color: transparent;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  color: #dfdfdf;
  font-size: 13px;
  line-height: 1;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;
}

.racing-tab:hover {
  border-color: #39d377;
}

.racing-tab.current {
  border-color: #39d377;
  background-color: #39d377;
  color: #000;
}

.race-content {
  height: 100%;
  padding-top: 20px;
  padding-right: 20px;
  padding-left: 20px;
  background-color: #060606;
}

.race-box-wp {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: -10px;
  margin-left: -10px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.race-box-col {
  display: inline-block;
  padding-right: 10px;
  padding-left: 10px;
}

.race-box {
  position: relative;
  overflow: hidden;
  max-width: 270px;
  min-width: 270px;
  margin-bottom: 20px;
  padding: 30px 20px 20px;
  border-style: solid;
  border-width: 2px;
  border-color: #060606;
  border-radius: 4px;
  background-color: #000;
  text-align: center;
}

.race-box.selected {
  border: 2px solid #4975ff;
}

.box-border {
  position: absolute;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  width: 100%;
  height: 5px;
  background-color: #0102f6;
}

.view-button {
  display: inline-block;
  padding: 10px 20px;
  border: 1px solid #484848;
  border-radius: 38px;
  background-color: transparent;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  color: #dfdfdf;
  font-size: 13px;
  line-height: 1.5;
  font-weight: 600;
  text-decoration: none;
  text-transform: uppercase;
  cursor: pointer;
}

.view-button:hover {
  border-color: #39d377;
  background-color: rgba(57, 211, 119, 0.2);
}

.box-title {
  margin-top: 16px;
  margin-bottom: 20px;
  color: #fff;
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
}

.horse-img {
  position: relative;
  z-index: 5;
  display: inline-block;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.horsepad-img {
  position: absolute;
  left: 29%;
  bottom: 31%;
  width: 45px;
  height: 35px;
  margin-right: auto;
  margin-left: auto;
}


/*
.andrew-grades {
    position: absolute;
    left: 0%;
    top: 0%;
    left: 50%;
    bottom: auto;
    height: 20px;
    width: 50%;
    color: #ffffff;
}
*/

.andrew-grades {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: auto;
    height: 20px;
    width: 50%;
    color: #ffffff;
    text-align: right;
    z-index: 1000;
}







.box-wave-block {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: auto;
  height: 102px;
}

.wave-line {
  position: absolute;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  height: 60px;
}

.box-number {
  position: absolute;
  left: 51%;
  bottom: 45%;
  z-index: 6;
  color: #030303;
  font-size: 20px;
  line-height: 26px;
  font-weight: 700;
}

.wave-bg {
  position: absolute;
  width: 100%;
  height: 50px;
  background-color: #121212;
}

.dropdown-list.w--open {
  margin-top: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 8px;
  background-color: #121212;
  box-shadow: 0 0 24px 0 #000;
  right: 0;
}

.dropdown-link {
  padding-top: 8px;
  padding-bottom: 8px;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  color: #999;
  font-weight: 500;
}

.dropdown-link:hover {
  color: #fc480d;
}

.hero-section {
  position: relative;
  overflow: hidden;
  padding-top: 337px;
  padding-bottom: 215px;
  background-image: url('../images/herohorseimg.png');
  background-position: 100% 100%;
  background-size: 1050px;
  background-repeat: no-repeat;
}

.container {
  max-width: 1730px;
  padding-right: 15px;
  padding-left: 15px;
}

.container.lg {
  max-width: 1780px;
}

.nav-btn {
  margin-left: 40px;
}

.btn-primary {
  display: inline-block;
  margin-left: 15px;
  padding: 15px 30px;
  border-radius: 30px;
  background-color: #39d377;
  box-shadow: 0 2px 7px 0 rgba(120, 137, 149, 0.25);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  font-family: Montserrat, sans-serif;
  color: #000;
  font-size: 16px;
  line-height: 1;
  font-weight: 700;
  text-decoration: none;
  text-transform: uppercase;
}

.btn-primary:hover {
  background-color: #00ff67;
}

.btn-primary.form {
  margin-left: 0px;
  padding-top: 25px;
  padding-bottom: 25px;
  color: #000;
  font-size: 24px;
  line-height: 29px;
}

.btn-primary.dash {
  display: none;
}

.btn-primary.sqr {
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 0px;
  color: #000;
  font-weight: 600;
}

.btn-primary.oth {
  width: 100%;
  max-width: 240px;
  padding-top: 25px;
  padding-bottom: 25px;
  border: 1px solid #39d377;
  background-color: transparent;
  box-shadow: 0 3px 6px 0 rgba(57, 211, 119, 0.25);
  color: #39d377;
  font-size: 24px;
  line-height: 29px;
}

.btn-primary.oth:hover {
  background-color: rgba(57, 211, 119, 0.2);
}

.btn-primary.trans {
  border: 1px solid #979797;
  background-color: transparent;
  color: #cacaca;
}

.btn-primary.trans:hover {
  background-color: hsla(0, 0%, 100%, 0.3);
  color: #fff;
}

.hero-text-block {
  position: relative;
  z-index: 20;
  width: 100%;
  max-width: 877px;
  padding-left: 85px;
}

.green-text {
  display: inline-block;
  color: #39d377;
}

.hero-p {
  margin-top: 22px;
  margin-bottom: 44px;
  color: #e0e0e0;
  font-size: 28px;
  line-height: 44px;
  font-weight: 300;
}

.hero-form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.form-input-home {
  height: auto;
  margin-bottom: 0px;
  padding: 23px 20px 23px 33px;
  border: 1px solid #39d377;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  background-color: #000;
  color: #39d377;
  font-size: 24px;
  line-height: 33px;
  font-weight: 300;
}

.form-input-home::-webkit-input-placeholder {
  color: #39d377;
}

.form-input-home:-ms-input-placeholder {
  color: #39d377;
}

.form-input-home::-ms-input-placeholder {
  color: #39d377;
}

.form-input-home::placeholder {
  color: #39d377;
}

.hero-img {
  z-index: 2;
  display: none;
}

.hero-block {
  background-image: none;
  background-position: 0px 0px;
  background-size: auto;
  background-repeat: repeat;
}

.black-gradient {
  position: absolute;
  left: auto;
  top: auto;
  right: 0%;
  bottom: 0%;
  z-index: 3;
  width: 425px;
  height: 270px;
  background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(#000));
  background-image: linear-gradient(180deg, transparent, #000);
}

.handicap-block {
  position: relative;
  overflow: hidden;
  padding-top: 326px;
  padding-bottom: 240px;
}

.section-inner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.section-heading {
  margin-top: 0px;
  margin-bottom: 50px;
  font-family: 'Playfair Display', sans-serif;
  color: #fff;
  font-size: 80px;
  line-height: 85px;
  text-transform: uppercase;
}

.section-heading.sm {
  margin-bottom: 8px;
  font-size: 60px;
  line-height: 65px;
}

.section-heading.s-sm {
  font-size: 54px;
  line-height: 72px;
  letter-spacing: 1px;
}

.handcap-image {
  width: 100%;
  height: auto;
  max-width: 938px;
  margin-left: auto;
  box-shadow: 28px 28px 80px 2px rgba(0, 0, 0, 0.5);
}

.headcap-text {
  width: 100%;
  max-width: 50%;
}

.section-p {
  margin-top: 20px;
  margin-bottom: 0px;
  color: rgba(255, 255, 255, 0.8);
  font-size: 27px;
  line-height: 44px;
  font-weight: 300;
}

.section-p.mt0 {
  margin-top: 0px;
}

.section-p.mb20 {
  margin-bottom: 50px;
}

.trip-note-block {
  position: relative;
  overflow: hidden;
  padding-top: 124px;
  padding-bottom: 350px;
}

.section-head-block {
  position: relative;
  z-index: 2;
  width: 100%;
  max-width: 1112px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}

.note-flow-block {
  position: relative;
  z-index: 2;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0px;
  padding-top: 200px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.flow-img {
  position: relative;
  z-index: 5;
  margin-left: -22px;
}

.note-wp {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 5;
  width: 100%;
  height: auto;
  max-width: 470px;
  text-align: center;
}

.note-title {
  margin-top: 18px;
  color: #39d377;
  font-size: 24px;
  line-height: 29px;
  font-weight: 700;
}

.note-p {
  margin-top: 30px;
  margin-bottom: 0px;
  opacity: 0;
  color: #fff;
  font-size: 16px;
  line-height: 28px;
  font-weight: 300;
}

.note-block {
  position: relative;
  max-width: 440px;
  min-width: 440px;
  margin-bottom: 30px;
}

.note-block.mt {
  margin-top: 50px;
  margin-bottom: 0px;
}

.note-block.top {
  margin-top: -150px;
  margin-bottom: 115px;
}

.note-block.middle {
  margin-bottom: 132px;
}

.explore-btn-block {
  position: absolute;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  z-index: 2;
  margin-right: auto;
  text-align: center;
}

.square-block {
  position: absolute;
  left: auto;
  top: 30%;
  right: 0%;
  bottom: auto;
  z-index: 1;
  display: inline-block;
  max-width: 500px;
}

.circuits-section {
  overflow: hidden;
  padding-top: 247px;
  padding-bottom: 180px;
}

.circuits-box-wp {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 100px;
  margin-right: -35px;
  margin-left: -35px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.circuits-box {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  border-radius: 8px;
  box-shadow: 0 12px 18px 0 rgba(0, 0, 0, 0.15);
}

.circuits-box.lst {
  margin-right: 0px;
}

.read-more-text {
  position: absolute;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
  z-index: 2;
  display: block;
  width: 100%;
  padding-top: 27px;
  padding-bottom: 34px;
  border-style: solid none none;
  border-width: 1px;
  border-color: #39d377;
  color: #39d377;
  font-size: 24px;
  line-height: 29px;
  font-weight: 700;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
}

.read-more-text:hover {
  color: #00ff67;
}

.circuits-title {
  display: inline-block;
  margin-top: 0px;
  margin-bottom: 3px;
  padding-left: 56px;
  background-image: url('../images/location-icon.svg');
  background-position: 0% 50%;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 36px;
  line-height: 44px;
  font-weight: 400;
  text-transform: uppercase;
}

.content-section {
  position: relative;
  overflow: hidden;
}

.get-section {
  overflow: hidden;
  padding-top: 207px;
  padding-bottom: 266px;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(82%, #000),
    color-stop(100%, #103d23),
    color-stop(0, #1c683b),
    to(#32b868)
  );
  background-image: linear-gradient(180deg, #000 82%, #103d23 100%, #1c683b 0, #32b868);
}

.get-form-block {
  max-width: 723px;
  margin-top: 50px;
  margin-right: auto;
  margin-left: auto;
}

.footer-section {
  padding-top: 67px;
  padding-bottom: 44px;
  background-image: linear-gradient(91deg, #000, rgba(0, 0, 0, 0.67) 44%, transparent),
    url('../images/footer-photo.png');
  background-position: 0px 0px, 100% 50%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
}

.social-link-wp {
  margin-top: 32px;
  margin-bottom: 30px;
}

.social-link {
  width: 18px;
  height: 18px;
  margin-right: 26px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.footer-p {
  margin-bottom: 0px;
  font-family: Poppins, sans-serif;
  color: hsla(0, 0%, 59.6%, 0.4);
  line-height: 24px;
  text-align: center;
}

.nav-home {
  margin-right: 0px;
  margin-left: 10px;
  padding-top: 0px;
  padding-bottom: 0px;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  color: #dfdfdf;
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  text-transform: uppercase;
}

.nav-home:hover {
  color: #99a8ca;
}

.nav-home.w--current {
  background-image: url('../images/currentdot.svg');
  background-position: 0% 50%;
  background-repeat: no-repeat;
  color: #fff;
}

.form-block {
  margin-bottom: 0px;
}

.loader-block {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.loader {
  width: 120px;
  height: 120px;
  margin: auto;
  border-style: solid;
  border-width: 15px;
  border-color: #1c2029 #1c2029 #39d377;
  border-radius: 100%;
}

.dashboard-body {
  overflow: hidden;
  height: 100vh;
  background-color: #000;
  font-family: Montserrat, sans-serif;
}

.close-btn {
  display: none;
}

.sign-up-wrapper {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 99;
  display: none;
  overflow: auto;
  padding-top: 50px;
  padding-bottom: 50px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
}

.signup-block {
  position: relative;
  width: 100%;
  max-width: 500px;
  padding: 30px;
  border-radius: 8px;
  background-color: #fff;
}

.close-icon {
  position: absolute;
  left: auto;
  top: 20px;
  right: 20px;
  bottom: auto;
  cursor: pointer;
}

.loader-inner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.home-body {
  background-color: #000;
  font-family: Montserrat, sans-serif;
}

.h-span-img {
  display: inline-block;
  width: 69px;
  height: 82px;
  background-image: url('../images/herotimg.svg');
  background-position: 0px 0px;
  background-size: 69px 82px;
  background-repeat: no-repeat;
  line-height: 69px;
}

.hero-span {
  color: #39d377;
}

.d-none {
  display: none;
}

.mask {
  height: auto;
}

.slide-nav {
  margin-bottom: -74px;
}

.handcap-img-wp {
  position: absolute;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 50%;
  margin-right: auto;
  padding-right: 100px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.handcap-line {
  position: absolute;
  left: auto;
  top: -11%;
  right: -7%;
  bottom: auto;
  z-index: 5;
  width: 100%;
  height: 100%;
  border: 1px solid #39d377;
}

.horseman-block {
  width: 100%;
  max-width: 350px;
  min-width: 350px;
  text-align: center;
}

.horseman-name {
  margin-top: 54px;
  margin-bottom: 20px;
  color: #fff;
  font-size: 60px;
  text-transform: uppercase;
  white-space: pre;
}

.horseman-date {
  color: #fff;
  font-size: 30px;
  line-height: 22px;
  font-weight: 200;
}

.flow-wp {
  position: relative;
}

.more-block {
  position: absolute;
  left: auto;
  top: 0%;
  right: -34%;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-bottom: 32px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.more-block.middle {
  padding-bottom: 54px;
}

.more-block.bottom {
  padding-bottom: 69px;
}

.circuits-text-block {
  position: relative;
  height: 100%;
  padding: 30px 44px 132px;
  border: 1px solid #39d377;
  text-align: center;
  cursor: default;
}

.circuits-col {
  max-width: 33.33%;
  padding-right: 35px;
  padding-left: 35px;
}

.circuits-col.lst {
  margin-right: 0px;
}

.footer-text {
  max-width: 370px;
}

.footer-para {
  margin-top: 10px;
  margin-bottom: 20px;
  font-family: Poppins, sans-serif;
  color: #a2a2a2;
  line-height: 24px;
}

.circuits-p {
  display: none;
  overflow: hidden;
  height: 220px;
  margin-top: 20px;
  margin-bottom: 0px;
  color: rgba(255, 255, 255, 0.8);
  font-size: 27px;
  line-height: 44px;
  font-weight: 300;
}

.handap-title-line {
  position: absolute;
  left: auto;
  top: 52%;
  right: auto;
  bottom: auto;
  width: 100%;
  height: 1.09px;
  margin-left: 400px;
  background-color: #39d377;
}

.trip-line {
  position: absolute;
  left: auto;
  top: 18%;
  right: 0%;
  bottom: 0%;
  width: 100%;
  height: 1.09px;
  margin-right: 1115px;
  background-color: #39d377;
}

.circuits-line {
  position: absolute;
  left: auto;
  top: 50%;
  right: auto;
  bottom: auto;
  width: 100%;
  height: 1.09px;
  margin-left: 1110px;
  background-color: #39d377;
}

.get-line {
  position: absolute;
  left: auto;
  top: 28%;
  right: 0%;
  bottom: 0%;
  width: 100%;
  height: 1.09px;
  margin-right: 880px;
  background-color: #39d377;
}

.nav-inner-home {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 130px;
  padding-bottom: 20px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.nav-inner-home.pricing {
  height: auto;
  padding-bottom: 8px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.login-wrapper {
  overflow: auto;
  width: 100vw;
  height: 100vh;
  padding: 16px;
  font-family: Montserrat, sans-serif;
}

.login-rightbar {
  display: block;
  overflow: auto;
  width: 100%;
  max-width: 500px;
  margin-left: auto;
  border-radius: 8px;
  background-color: #060606;
}

.slider-wp {
  width: 100%;
  max-width: 100%;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.slider-wp.dash {
  padding-right: 15px;
  padding-left: 15px;
}

.rightbar-inner {
  padding: 20px 16px 30px;
}

.login-rightbar-head {
  text-align: center;
}

.login-rightbar-head.mt14 {
  margin-top: 16px;
}

.login-rightbar-content.regi {
  padding-top: 15px;
}

.signup-wrapper {
  padding: 40px 72px 20px;
}

.signup-text {
  margin-top: 0px;
  margin-bottom: 25px;
  color: #39d377;
  font-size: 20px;
  line-height: 24px;
}

.signup-text.sm {
  margin-bottom: 30px;
  font-size: 16px;
  line-height: 22px;
}

.form-lable {
  margin-bottom: 7px;
  color: #fff;
  font-size: 13px;
  line-height: 16px;
  font-weight: 600;
}

.form-input {
  position: relative;
  height: auto;
  margin-bottom: 0px;
  padding: 12px 40px 12px 16px;
  border: 1px solid transparent;
  border-radius: 3px;
  background-color: #121212;
  color: #fff;
  line-height: 17px;
  font-weight: 500;
}

.form-input::-webkit-input-placeholder {
  color: rgba(183, 187, 196, 0.6);
}

.form-input:-ms-input-placeholder {
  color: rgba(183, 187, 196, 0.6);
}

.form-input::-ms-input-placeholder {
  color: rgba(183, 187, 196, 0.6);
}

.form-input::placeholder {
  color: rgba(183, 187, 196, 0.6);
}

.checkbox {
  width: 16px;
  height: 16px;
  margin-top: 2px;
  border: 1px solid #262626;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.8);
}

.checkbox.w--redirected-checked {
  border-color: #39d377;
  background-color: transparent;
  background-size: 11px;
}

.checkbox.w--redirected-focus {
  box-shadow: none;
}

.checkbox-label {
  margin-bottom: 0px;
  margin-left: 8px;
  font-size: 15px;
  line-height: 18px;
  font-weight: 500;
}

.checkbox-field {
  margin-right: auto;
  margin-bottom: 0px;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  color: #999;
  cursor: pointer;
}

.checkbox-field:hover {
  color: #b6b6b6;
}

.forgot-link {
  display: inline-block;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  color: #39d377;
  font-size: 15px;
  line-height: 18px;
  font-weight: 500;
  text-decoration: none;
}

.forgot-link:hover {
  color: #00ff67;
}

.form-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: -8px;
  margin-bottom: 18px;
  margin-left: -8px;
}

.form-btn-block {
  text-align: center;
}

.form-btn-block.mt20 {
  margin-top: 20px;
}

.btn-square {
  width: 100%;
  max-width: 260px;
  padding-top: 16px;
  padding-bottom: 16px;
  border-radius: 3px;
  background-color: #39d377 !important;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  color: #232630;
  font-size: 15px;
  line-height: 18px;
  font-weight: 700;
  text-align: center;
  cursor: pointer;
  margin-top: auto;
}

.btn-square:hover {
  background-color: #00ff67 !important;
}

.btn-square.f-wth {
  max-width: none;
}

.btn-square.pay {
  width: auto;
  max-width: none;
  padding-right: 26px;
  padding-left: 26px;
}

.social-signup-wrapper {
  padding: 20px 84px 10px;
}

.social-signup-text {
  margin-bottom: 15px;
  color: #fff;
  font-size: 15px;
  line-height: 18px;
  font-weight: 600;
  text-align: center;
}

.social-link-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 12px;
  padding: 13px 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #262626;
  border-radius: 3px;
  background-color: #0e0e0e;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.8);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  color: #fff;
  font-size: 15px;
  line-height: 18px;
  font-weight: 500;
  text-decoration: none;
}

.social-link-block:hover {
  background-color: #383838;
}

.social-link-block.social-login.d-none {
  display: none;
}

.social-text {
  margin-bottom: 0px;
  margin-left: 20px;
}

.rightbar-btm-text {
  margin-bottom: 0px;
  padding-right: 30px;
  padding-left: 30px;
  color: #999;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
}

.btm-link {
  display: inline-block;
  color: #39d377;
  text-decoration: none;
}

.slider {
  height: auto;
  background-color: transparent;
}

.mask-2 {
  height: auto;
}

.slider-content {
  width: 100%;
  max-width: 440px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}

.silder-title {
  margin-top: 50px;
  margin-bottom: 30px;
  color: #39d377;
  font-size: 40px;
  line-height: 49px;
}

.slider-para {
  margin-bottom: 0px;
  color: #fff;
  font-size: 21px;
  line-height: 28px;
  font-weight: 300;
}

.slider-nav {
  bottom: -52px;
  height: auto;
  padding-top: 0px;
}

.login-body {
  /* overflow: hidden; */
  height: 100vh;
  background-color: #000;
  background-image: url('../images/bgimg.png');
  background-position: 0% 100%;
  background-size: 992px;
  background-repeat: no-repeat;
  font-family: Montserrat, sans-serif;
}

.login-body.horse {
  background-image: url('../images/bglight.png');
}

.eye-icon {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: 0%;
  padding-right: 30px;
  padding-left: 15px;
  background-color: transparent;
  background-image: url('../images/eyeonicon.svg');
  background-position: 50% 50%;
  background-repeat: no-repeat;
  cursor: pointer;
}

.eye-icon.off {
  background-image: url('../images/eye-off.svg');
}

.input-block {
  position: relative;
}

.form-col {
  width: 100%;
  padding-right: 8px;
  padding-left: 8px;
}

.forgot-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.forgot-block.mb.d-none {
  display: none;
}

.register-wrapper {
  padding: 22px 72px;
}

.checkbox-block {
  position: relative;
  max-width: 200px;
  min-width: 200px;
  padding: 30px 38px;
  border-radius: 3px;
  background-color: #121212;
  color: #fff;
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
  cursor: pointer;
}

.slider-img {
  margin-right: auto;
  margin-left: auto;
}

.user-icon {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: 0%;
  padding-right: 30px;
  padding-left: 15px;
  background-color: transparent;
  background-image: url('../images/usericon.svg');
  background-position: 50% 50%;
  background-repeat: no-repeat;
  cursor: default;
}

.navbar-dashboard {
  padding: 18px 20px 10px;
  background-color: #000;
}

.login-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.onboarding-text-block {
  padding-top: 120px;
  padding-bottom: 70px;
  text-align: center;
}

.onboarding-heading {
  margin-bottom: 30px;
  font-family: Montserrat, sans-serif;
  color: #39d377;
  font-size: 40px;
  line-height: 50px;
  text-transform: none;
}

.onboarding-para {
  max-width: 48%;
  margin-right: auto;
  margin-bottom: 0px;
  margin-left: auto;
  color: #fff;
  font-size: 21px;
  line-height: 28px;
  font-weight: 300;
  text-align: center;
}

.onboarding-para.lg {
  max-width: 76%;
}

.onboarding-selection {
  max-width: 60%;
  margin-right: auto;
  margin-left: auto;
  padding: 30px 36px;
  border-radius: 8px;
  background-color: #060606;
  text-align: center;
}

.checkbox-wp {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: -20px;
  margin-left: -20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.checkbox-wp.sample {
  display: none;
}

.checkbox-img {
  width: 100px;
  height: 100px;
  margin-right: auto;
  margin-bottom: 18px;
  margin-left: auto;
}

.onboarding-content.recetrack {
  display: none;
}

.checkbox-col {
  margin-bottom: 20px;
  padding-right: 20px;
  padding-left: 20px;
}

.pricing-content {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: auto;
  height: 100vh;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.pricing-content.normal {
  display: block;
  padding-top: 48px;
  padding-right: 1px;
  padding-left: 15px;
}

.pricing-block {
  width: 100%;
  max-width: 70%;
  margin-right: auto;
  margin-left: auto;
}

.pricing-box-wp {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin-top: 30px;
  padding: 20px;
  border-radius: 6px;
  background-color: #060606;
}

.pricing-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  max-width: 50%;
  margin-right: 25px;
  padding: 30px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  align-items: center;
  border: 1px solid transparent;
  border-radius: 3px;
  background-color: #121212;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  text-align: center;
}

.pricing-box:hover {
  border-color: #39d377;
}

.pricing-box.pro {
  background-image: url('../images/lightning.svg');
  background-position: 95% 5%;
  background-repeat: no-repeat;
}

.plan-text {
  margin-top: 0px;
  margin-bottom: 0px;
  color: #fff;
  line-height: 17px;
}

.pricing-text {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10px;
  margin-bottom: 6px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  color: #fff;
  font-size: 72px;
  line-height: 88px;
  font-weight: 700;
}

.doller-text {
  display: inline-block;
  margin-right: 2px;
  color: #39d377;
  font-size: 14px;
  line-height: 35px;
  font-weight: 500;
}

.pricing-savings-text {
  color: #fff;
}

.plan-p {
  margin-right: auto;
  margin-bottom: 33px;
  margin-left: auto;
  color: #39d377;
  line-height: 17px;
  font-weight: 500;
}

.pricing-title {
  margin-top: 0px;
  margin-bottom: 0px;
  color: #39d377;
  font-size: 16px;
  line-height: 22px;
}

.track-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.track-left {
  width: 100%;
  margin-right: 30px;
}

.track-right {
  width: 100%;
  max-width: 446px;
  min-width: 446px;
  margin-top: 15px;
  padding: 35px 20px 20px;
  border-radius: 8px;
  background-color: #060606;
}

.track-right.mobile {
  display: none;
}

.track-list {
  margin-bottom: 0px;
  padding-left: 0px;
}

.track-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 15px;
  padding: 15px 25px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #121212;
}

.track-title {
  margin-top: 0px;
  margin-bottom: 0px;
  color: #39d377;
  font-size: 16px;
  line-height: 22px;
}

.race-text {
  margin-bottom: 0px;
  color: #fff;
  font-size: 16px;
  line-height: 22px;
}

.track-time {
  display: inline-block;
  padding: 8px 15px;
  border-radius: 4px;
  background-color: #000000;
}

.time-text {
  margin-bottom: 0px;
  color: #888;
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
}

.btn-cart {
  display: block;
  width: 100%;
  max-width: 151px;
  margin-left: auto;
  padding: 16px 20px;
  border: 1px solid #39d377;
  border-radius: 3px;
  background-color: #121212;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  color: #fff;
  font-size: 15px;
  line-height: 1;
  font-weight: 700;
  text-align: center;
}

.btn-cart:hover {
  background-color: #1d1d1d;
}

.btn-cart.remove {
  color: #8f8f8f;
}

.track-info {
  width: 100%;
  max-width: 33.33%;
  margin-right: auto;
}

.track-info.trans {
  max-width: 60%;
}

.track-btn {
  width: 100%;
  max-width: 33.33%;
  margin-left: auto;
}

.track-btn.trans {
  max-width: 20%;
}

.track-right-title {
  margin-top: 0px;
  margin-bottom: 0px;
  color: #39d377;
  font-size: 24px;
  line-height: 22px;
}

.order-list {
  position: relative;
  margin-top: 36px;
  margin-bottom: 0px;
  padding-left: 0px;
}

.order-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 15px;
  padding: 15px 16px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 4px;
  background-color: #000;
}

.order-item.amount {
  position: static;
  bottom: 0%;
  display: block;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
}

.amount-text {
  display: inline-block;
  margin-top: 0px;
  margin-bottom: 0px;
  color: #fff;
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
}

.amount-text.num {
  color: #39d377;
}

.amount-block {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-bottom: 20px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.payment-model-wrapper {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 99;
  display: none;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.75);
}

.payment-model-block {
  width: 100%;
  max-width: 480px;
  min-width: 480px;
  margin-right: auto;
  margin-left: auto;
  border: 1px solid #262626;
  border-radius: 3px;
  background-color: #000;
}

.payment-model-top {
  padding: 30px;
}

.payment-btn-block {
  padding: 10px 30px;
  background-color: #121212;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.btn-cancel {
  display: inline-block;
  margin-right: 30px;
  color: #39d377;
  font-size: 15px;
  line-height: 18px;
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;
  background: transparent !important;
}

.track-col {
  width: 100%;
  max-width: 33.33%;
}

.track-col.right {
  text-align: right;
}

.track-col.center {
  text-align: center;
}

.tripnote-slider-wp {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 9999;
  display: none;
  background-color: rgba(0, 0, 0, 0.85);
}

.close-img {
  position: absolute;
  left: auto;
  top: 3%;
  right: 3%;
  bottom: auto;
}

.t-slider-inner {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.eye-off {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: none;
  padding-right: 30px;
  padding-left: 15px;
  background-color: transparent;
  background-image: url('../images/eye-off.svg');
  background-position: 50% 50%;
  background-repeat: no-repeat;
  cursor: pointer;
}

.payment-model-inner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.terms-text {
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  color: hsla(0, 0%, 59.6%, 0.4);
  text-decoration: none;
}

.terms-text:hover {
  color: #39d377;
  text-decoration: none;
}

.hero-form-block {
  display: none;
}

.transaction-wrapper {
  max-width: 76%;
  margin-right: auto;
  margin-left: auto;
}

.transaction-list-wp {
  width: 100%;
}

.status-block {
  display: inline-block;
  margin-top: 5px;
  margin-bottom: 0px;
  padding: 5px 10px;
  border-radius: 12px;
  background-color: rgba(252, 72, 13, 0.24);
  color: #fc480d;
  line-height: 1;
  font-weight: 500;
}

.transaction-amount {
  color: #fff;
  font-size: 18px;
  line-height: 22px;
  font-weight: 600;
  text-align: right;
}

@media screen and (min-width: 1280px) {
  .more-block {
    right: -27%;
  }
}

@media screen and (min-width: 1920px) {
  .profile-img {
    opacity: 0;
  }
  .nav-btn {
    margin-left: 33px;
  }
  .btn-primary {
    margin-left: 17px;
  }
  .section-p.mb20 {
    margin-bottom: 20px;
  }
  .nav-home {
    margin-left: 0px;
    padding-right: 43px;
    padding-left: 43px;
  }
  .nav-home.w--current {
    background-position: 10% 50%;
  }
  .more-block.middle {
    padding-bottom: 48px;
  }
  .circuits-p {
    display: none;
  }
}

@media screen and (max-width: 991px) {
  h1 {
    font-size: 48px;
    line-height: 58px;
  }
  .navbar {
    padding: 12px 16px;
  }
  .navbar.home {
    z-index: 99;
  }
  .navbar.pricing {
    padding-top: 12px;
    padding-right: 0px;
    padding-left: 0px;
  }
  .nav-link {
    display: block;
    margin-right: 10px;
    margin-bottom: 0px;
    margin-left: 10px;
    padding: 10px;
    border-bottom-style: none;
    text-align: center;
    white-space: nowrap;
  }
  .nav-link.w--current {
    border-bottom-style: none;
  }
  .nav-link._w--current {
    line-height: 16px;
  }
  .nav-inner {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  /* .profile-block {
    display: none;
  } */
  .profile-block.mobile {
    display: block;
    margin-left: auto;
  }
  .profile-img {
    margin-right: 0px;
  }
  .nav-menu {
    position: absolute;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: auto;
    margin-top: 62px;
    background-color: transparent;
    -o-object-fit: fill;
    object-fit: fill;
  }
  .nav-menu.home {
    margin-top: 74px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .brand-logo {
    padding-bottom: 0px;
  }
  .content-wrapper {
    padding-top: 60px;
    padding-right: 16px;
    padding-left: 16px;
  }
  .content-leftside {
    overflow: visible;
  }
  .content-rightbar {
    position: fixed;
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    z-index: 99;
    overflow: auto;
    max-width: 100%;
    min-width: 100%;
    padding-top: 109px;
    padding-bottom: 75px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    border-top-right-radius: 0px;
  }
  .rightbar-head-block {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }
  .dis-surface-block {
    max-width: 50%;
    min-width: 50%;
  }
  .dis-surface-block.left {
    max-width: 50%;
    min-width: 50%;
  }
  .rightbar-button {
    position: fixed;
    left: 0%;
    top: auto;
    right: 0%;
    bottom: 0%;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .race-content {
    padding-top: 16px;
    padding-right: 0px;
    padding-left: 0px;
  }
  .race-box-wp {
    margin-right: -8px;
    margin-left: -8px;
  }
  .race-box-col {
    width: 100%;
    max-width: 33.33%;
    padding-right: 8px;
    padding-left: 8px;
  }
  .race-box {
    max-width: none;
    min-width: auto;
  }
  .dropdown-list.w--open {
    right: 0px;
    width: 100%;
    max-width: 200px;
    min-width: 200px;
  }
  .hero-section {
    height: auto;
    padding-top: 150px;
    padding-bottom: 60px;
    background-position: 100% 24%;
    background-size: 430px;
  }
  .nav-btn {
    width: 100%;
    margin-left: 0px;
    padding-right: 0px;
    padding-left: 0px;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    color: #12141b;
    text-align: right;
  }
  .btn-primary.form {
    padding-right: 14px;
    padding-left: 14px;
  }
  .hero-text-block {
    padding-left: 0px;
  }
  .hero-p {
    font-size: 18px;
    line-height: 28px;
  }
  .hero-img {
    margin-left: -18px;
    padding-top: 55px;
    padding-right: 420px;
  }
  .hero-block {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .black-gradient {
    bottom: 29%;
    width: 150px;
    height: 230px;
  }
  .handicap-block {
    padding-top: 80px;
    padding-bottom: 80px;
    background-size: cover;
  }
  .section-inner {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .section-heading {
    font-size: 44px;
    line-height: 52px;
  }
  .section-heading.sm {
    font-size: 44px;
    line-height: 52px;
  }
  .section-heading.s-sm {
    font-size: 44px;
    line-height: 52px;
  }
  .handcap-image {
    width: 100%;
    max-width: none;
    min-width: auto;
    margin-right: auto;
    margin-left: auto;
  }
  .headcap-text {
    max-width: 100%;
    margin-left: 0px;
    text-align: center;
  }
  .section-p.mb20 {
    margin-bottom: 20px;
  }
  .trip-note-block {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .note-flow-block {
    margin-top: 100px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .flow-img {
    display: none;
  }
  .note-wp {
    position: static;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    max-width: none;
    min-width: auto;
    margin-top: 50px;
    margin-left: 0px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .note-block {
    max-width: 50%;
    min-width: 50%;
    margin-bottom: 50px;
    padding-right: 15px;
    padding-left: 15px;
  }
  .note-block.mt {
    margin-top: 0px;
    margin-bottom: 50px;
  }
  .note-block.top {
    margin-top: 0px;
    margin-bottom: 50px;
  }
  .note-block.middle {
    margin-bottom: 50px;
  }
  .explore-btn-block {
    margin-top: 40px;
    padding-right: 0px;
  }
  .square-block {
    top: 22%;
    max-width: 460px;
  }
  .circuits-section {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .circuits-box-wp {
    margin-top: 60px;
    margin-right: 0px;
    margin-left: 0px;
    padding-right: 0px;
    padding-left: 0px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .circuits-box {
    margin-right: 0px;
    margin-bottom: 30px;
    padding-right: 10px;
    padding-left: 10px;
  }
  .circuits-box.lst {
    margin-bottom: 0px;
  }
  .circuits-title {
    font-size: 24px;
  }
  .get-section {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  .footer-section {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .nav-home {
    display: inline-block;
    width: auto;
    margin-right: auto;
    margin-bottom: 10px;
    margin-left: auto;
    padding-top: 3px;
    padding-bottom: 3px;
    text-align: center;
  }
  .nav-home.d-none {
    display: none;
  }
  .menu-button {
    display: none;
    padding: 0px 12px;
  }
  .menu-button.w--open {
    background-color: transparent;
  }
  .top-line {
    width: 18px;
    height: 3px;
    margin-top: 5px;
    margin-bottom: 5px;
    padding-right: 0px;
    padding-bottom: 0px;
    background-color: #b5b8bf;
  }
  .icon {
    display: none;
  }
  .middle-line {
    width: 18px;
    height: 3px;
    margin-top: 5px;
    margin-bottom: 5px;
    padding-right: 0px;
    padding-bottom: 0px;
    background-color: #b5b8bf;
  }
  .bottom-line {
    width: 18px;
    height: 3px;
    margin-top: 5px;
    margin-bottom: 5px;
    padding-right: 0px;
    padding-bottom: 0px;
    background-color: #b5b8bf;
  }
  .menu-inner {
    overflow: auto;
    width: 100%;
    height: auto;
    padding: 16px 20px 10px;
    background-color: #12141b;
    box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.4);
    text-align: center;
  }
  .close-btn {
    display: block;
    margin-left: auto;
    cursor: pointer;
  }
  .brand-logo-home {
    padding-bottom: 0px;
  }
  .h-span-img {
    width: 50px;
    height: 50px;
    background-position: 50% 50%;
    background-size: 40px;
    line-height: 50px;
  }
  .handcap-img-wp {
    position: relative;
    max-width: 100%;
    margin-right: 0px;
    margin-bottom: 90px;
    padding-right: 20px;
    padding-left: 20px;
  }
  .handcap-line {
    display: none;
  }
  .horseman-name {
    font-size: 40px;
    line-height: 30px;
  }
  .more-block {
    position: static;
    margin-top: 20px;
    padding-bottom: 0px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .more-block.middle {
    padding-bottom: 0px;
  }
  .more-block.bottom {
    padding-bottom: 0px;
  }
  .circuits-text-block {
    height: auto;
    padding-right: 20px;
    padding-left: 20px;
  }
  .circuits-col {
    max-width: 50%;
    margin-right: 0px;
    padding-right: 0px;
    padding-left: 0px;
  }
  .handap-title-line {
    top: 68%;
    margin-left: 476px;
  }
  .trip-line {
    top: 10%;
    margin-right: 646px;
  }
  .circuits-line {
    top: 77%;
    margin-left: 570px;
  }
  .get-line {
    top: 18%;
    margin-right: 617px;
  }
  .man-image {
    width: 220px;
  }
  .nav-inner-home {
    height: 90px;
    padding-bottom: 0px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .nav-inner-home.pricing {
    padding-bottom: 0px;
  }
  .login-wrapper {
    background-image: none;
    background-position: 0px 0px;
    background-size: auto;
    background-repeat: repeat;
  }
  .login-rightbar.f-wth {
    max-width: 100%;
    margin-left: 0px;
  }
  .signup-wrapper {
    padding-right: 25px;
    padding-left: 25px;
  }
  .social-signup-wrapper {
    padding-right: 25px;
    padding-left: 25px;
  }
  .slider-content {
    padding-right: 10px;
    padding-left: 10px;
  }
  .silder-title {
    margin-top: 30px;
    margin-bottom: 20px;
    font-size: 28px;
    line-height: 40px;
  }
  .slider-para {
    font-size: 18px;
  }
  .login-body.horse {
    background-image: none;
    background-position: 0px 0px;
    background-size: auto;
    background-repeat: repeat;
  }
  .register-wrapper {
    padding-right: 25px;
    padding-left: 25px;
  }
  .slider-img {
    max-width: 160px;
  }
  .navbar-dashboard {
    padding: 12px;
  }
  .onboarding-text-block {
    padding-top: 70px;
  }
  .onboarding-heading {
    margin-bottom: 15px;
    font-size: 32px;
    line-height: 40px;
  }
  .onboarding-para {
    max-width: 70%;
    font-size: 17px;
    line-height: 26px;
  }
  .pricing-content.normal {
    position: relative;
    padding-top: 20px;
    padding-right: 0px;
    padding-left: 0px;
  }
  .pricing-block {
    max-width: 90%;
  }
  .track-wrapper {
    position: relative;
    display: block;
    padding-bottom: 165px;
  }
  .track-right {
    display: none;
  }
  .track-right.mobile {
    position: fixed;
    left: 0%;
    top: auto;
    right: 0%;
    bottom: 0%;
    z-index: 10;
    display: block;
    width: auto;
    max-width: none;
    min-width: auto;
    margin-top: 0px;
    padding: 15px;
    border-radius: 0px;
  }
  .order-item.normal {
    display: block;
    margin-top: 0px;
  }
  .transaction-wrapper {
    position: relative;
    display: block;
    max-width: 100%;
  }
}

@media screen and (max-width: 767px) {
  h1 {
    font-size: 38px;
    line-height: 46px;
  }
  .main-wrapper {
    height: auto;
  }
  .nav-link {
    margin-right: 2px;
    margin-left: 2px;
    font-size: 12px;
  }
  .dropdown-toggle {
    padding-right: 20px;
    font-size: 12px;
  }
  .profile-arrow {
    font-size: 12px;
  }
  .brand-logo {
    padding-left: 0px;
  }
  .content-head-text {
    font-size: 16px;
    line-height: 20px;
  }
  .date-text {
    font-size: 15px;
  }
  .date-text.sm {
    line-height: 20px;
  }
  .rightbar-head-text {
    font-size: 22px;
    line-height: 28px;
  }
  .trip-title-block {
    margin-bottom: 12px;
  }
  .trip-title {
    font-size: 16px;
    line-height: 22px;
  }
  .trip-sub-title {
    font-size: 14px;
    line-height: 20px;
  }
  .surface-type {
    font-size: 14px;
    line-height: 24px;
  }
  .btn {
    padding-top: 14px;
    padding-bottom: 14px;
  }
  .race-box-col {
    max-width: 50%;
  }
  .dropdown-link {
    padding-right: 12px;
    padding-left: 12px;
  }
  .btn-primary {
    padding: 10px 12px;
    font-size: 14px;
  }
  .btn-primary.form {
    width: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    font-size: 18px;
    line-height: 24px;
  }
  .btn-primary.oth {
    padding-top: 14px;
    padding-bottom: 14px;
    font-size: 20px;
    line-height: 24px;
  }
  .hero-text-block {
    max-width: none;
    margin-left: 0px;
  }
  .hero-p {
    margin-bottom: 30px;
  }
  .hero-form {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
  .hero-form.center {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .form-input-home {
    margin-bottom: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
  }
  .hero-img {
    position: relative;
    max-width: 400px;
    margin-right: auto;
    margin-bottom: -34px;
    margin-left: auto;
    padding-top: 20px;
    padding-right: 0px;
  }
  .hero-block {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .handicap-block {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .section-inner {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .section-heading {
    margin-bottom: 20px;
    font-size: 30px;
    line-height: 42px;
  }
  .section-heading.sm {
    font-size: 30px;
    line-height: 42px;
  }
  .section-heading.s-sm {
    font-size: 30px;
    line-height: 42px;
  }
  .handcap-image {
    width: 100%;
  }
  .headcap-text {
    max-width: none;
    margin-top: 40px;
    margin-left: 0px;
    text-align: center;
  }
  .section-p {
    font-size: 18px;
    line-height: 36px;
  }
  .trip-note-block {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .note-flow-block {
    margin-top: 60px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .note-wp {
    max-width: none;
    min-width: auto;
    margin-left: 0px;
    padding-left: 0px;
  }
  .note-block.mt {
    margin-top: 0px;
  }
  .explore-btn-block {
    position: static;
    margin-top: 0px;
  }
  .square-block {
    display: none;
  }
  .circuits-section {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .circuits-box-wp {
    margin-top: 40px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .circuits-box {
    margin-bottom: 30px;
  }
  .read-more-text {
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 20px;
    line-height: 26px;
  }
  .get-section {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .brand-logo-home {
    padding-left: 0px;
  }
  .handcap-img-wp {
    margin-bottom: 50px;
  }
  .horseman-block {
    max-width: none;
    min-width: auto;
  }
  .horseman-name {
    margin-top: 20px;
    font-size: 30px;
    line-height: 24px;
  }
  .horseman-date {
    font-size: 20px;
    line-height: 18px;
  }
  .circuits-text-block {
    padding-bottom: 70px;
  }
  .man-image {
    width: 150px;
  }
  .login-rightbar {
    max-width: none;
  }
  .slider-wp {
    display: none;
  }
  .slider-wp.dash {
    display: block;
  }
  .rightbar-inner {
    padding-right: 12px;
    padding-bottom: 20px;
    padding-left: 12px;
  }
  .login-rightbar-content {
    padding-top: 20px;
  }
  .signup-wrapper {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .social-signup-wrapper {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .silder-title {
    margin-top: 20px;
    margin-bottom: 15px;
    font-size: 22px;
    line-height: 28px;
  }
  .slider-para {
    font-size: 16px;
    line-height: 26px;
  }
  .checkbox-block {
    max-width: 140px;
    min-width: 140px;
    padding: 16px;
    font-size: 15px;
    line-height: 20px;
  }
  .slider-img {
    width: 120px;
  }
  .onboarding-text-block {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .onboarding-heading {
    font-size: 20px;
    line-height: 28px;
  }
  .onboarding-para.lg {
    margin-top: 10px;
    padding-right: 15px;
    padding-left: 15px;
  }
  .onboarding-selection {
    max-width: 100%;
    padding: 20px;
  }
  .checkbox-wp {
    margin-right: -10px;
    margin-left: -10px;
  }
  .checkbox-img {
    width: 60px;
    height: 60px;
    margin-bottom: 15px;
  }
  .checkbox-col {
    padding-right: 10px;
    padding-left: 10px;
  }
  .pricing-block {
    height: 100%;
    max-width: 100%;
  }
  .pricing-box-wp {
    margin-top: 20px;
    padding: 20px;
  }
  .pricing-box {
    margin-right: 20px;
  }
  .pricing-title {
    text-align: center;
  }
  .track-wrapper {
    padding-bottom: 160px;
  }
  .track-item {
    margin-top: 10px;
    padding: 10px;
  }
  .track-title {
    font-size: 14px;
    line-height: 20px;
  }
  .race-text {
    font-size: 13px;
    line-height: 18px;
  }
  .track-time {
    padding-right: 10px;
    padding-left: 10px;
  }
  .time-text {
    font-size: 13px;
    line-height: 18px;
  }
  .btn-cart {
    padding: 12px;
  }
  .payment-model-block {
    max-width: 100%;
    min-width: 100%;
  }
  .payment-model-top {
    padding: 16px;
  }
  .close-img {
    width: 36px;
  }
  .payment-model-inner {
    padding-right: 8px;
    padding-left: 8px;
  }
  .status-block {
    font-size: 12px;
  }
}

@media screen and (max-width: 479px) {
  h1 {
    font-size: 28px;
    line-height: 38px;
  }
  .profile-img {
    height: 34px;
    max-width: 34px;
    min-width: 34px;
  }
  .rightbar-head-block {
    padding: 15px 16px;
  }
  .rightbar-head-text {
    font-size: 20px;
  }
  .trip-info-block {
    padding: 16px;
  }
  .dis-surface-block {
    max-width: 100%;
    min-width: 100%;
  }
  .dis-surface-block.left {
    max-width: 100%;
    min-width: 100%;
    margin-right: 0px;
    margin-bottom: 10px;
  }
  .distance-wp {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .summary-block {
    margin-top: 10px;
    margin-right: 12px;
    padding-right: 8px;
    padding-left: 8px;
    font-size: 13px;
  }
  .rightbar-button {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-right: 10px;
    padding-left: 10px;
  }
  .btn {
    max-width: none;
    font-size: 14px;
  }
  .race-box-col {
    max-width: 100%;
    padding-right: 8px;
    padding-left: 8px;
  }
  .dropdown-list.w--open {
    max-width: 180px;
    min-width: 180px;
  }
  .hero-section {
    background-position: 100% 30%;
    background-size: 410px;
  }
  .btn-primary.form {
    width: 100%;
    font-size: 16px;
    line-height: 21px;
  }
  .btn-primary.oth {
    font-size: 18px;
    line-height: 22px;
  }
  .hero-text-block {
    z-index: 5;
    text-align: center;
  }
  .hero-p {
    font-size: 16px;
    line-height: 26px;
  }
  .hero-form.center {
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .form-input-home {
    padding-left: 20px;
    font-size: 22px;
    line-height: 30px;
    text-align: center;
  }
  .form-input-home.center {
    text-align: center;
  }
  .black-gradient {
    left: 0%;
    top: 0%;
    right: 0%;
    bottom: 0%;
    width: auto;
    height: auto;
    background-image: linear-gradient(150deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.59) 65%, #000 71%, #000);
  }
  .handicap-block {
    margin-top: 0px;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .section-heading {
    margin-bottom: 15px;
    font-size: 22px;
    line-height: 30px;
  }
  .section-heading.sm {
    font-size: 22px;
    line-height: 32px;
  }
  .section-heading.s-sm {
    font-size: 22px;
    line-height: 32px;
  }
  .handcap-image {
    max-width: none;
  }
  .headcap-text {
    margin-top: 30px;
  }
  .section-p {
    margin-top: 15px;
    font-size: 14px;
    line-height: 20px;
  }
  .trip-note-block {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .note-flow-block {
    margin-top: 40px;
    padding-top: 0px;
  }
  .note-wp {
    padding-right: 10px;
    padding-left: 10px;
  }
  .note-title {
    margin-top: 15px;
  }
  .note-p {
    margin-top: 15px;
    font-size: 14px;
  }
  .note-block {
    max-width: 100%;
    min-width: auto;
    margin-bottom: 25px;
    padding-right: 0px;
    padding-left: 0px;
  }
  .note-block.mt {
    margin-top: 0px;
    margin-bottom: 30px;
  }
  .note-block.top {
    margin-bottom: 30px;
  }
  .note-block.middle {
    margin-bottom: 30px;
  }
  .explore-btn-block {
    margin-top: 0px;
  }
  .circuits-section {
    padding-top: 40px;
    padding-bottom: 40px;
    background-position: 10% 0%, 0px 0px;
    background-size: 210px, contain;
  }
  .circuits-box {
    max-width: 100%;
    margin-right: 0px;
    padding-right: 0px;
    padding-left: 0px;
  }
  .circuits-title.one {
    background-position: 20% 50%;
    background-size: 18px;
    font-size: 18px;
    line-height: 30px;
  }
  .circuits-title.two {
    background-position: 10% 50%;
    background-size: 18px;
    font-size: 18px;
    line-height: 30px;
  }
  .circuits-title.three {
    background-position: 8% 50%;
    background-size: 18px;
    font-size: 18px;
    line-height: 30px;
  }
  .get-section {
    padding-top: 40px;
    padding-bottom: 40px;
    background-position: 50% 0%;
    background-size: 270px;
  }
  .get-form-block {
    margin-top: 30px;
  }
  .form-block {
    margin-bottom: 0px;
  }
  .menu-inner {
    padding-right: 0px;
    padding-left: 0px;
  }
  .h-span-img {
    width: 40px;
    height: 36px;
    background-size: 30px;
    line-height: 30px;
  }
  .slide-nav {
    margin-bottom: -50px;
  }
  .handcap-img-wp {
    margin-bottom: 30px;
  }
  .horseman-name {
    margin-bottom: 10px;
    font-size: 22px;
  }
  .horseman-date {
    font-size: 16px;
  }
  .circuits-col {
    max-width: 100%;
  }
  .footer-text {
    text-align: center;
  }
  .circuits-p {
    font-size: 16px;
    line-height: 20px;
  }
  .circuits-p {
    height: auto;
  }
  .note-img {
    width: 110px;
  }
  .signup-wrapper {
    padding-right: 0px;
    padding-left: 0px;
  }
  .checkbox-label {
    margin-left: 5px;
    font-size: 14px;
  }
  .forgot-link {
    font-size: 13px;
  }
  .form-row {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .btn-square {
    padding-top: 12px;
    padding-bottom: 12px;
  }
  .social-signup-wrapper {
    padding-right: 0px;
    padding-left: 0px;
  }
  .btm-link {
    margin-top: 8px;
  }
  .form-col.mb {
    margin-bottom: 20px;
  }
  .forgot-block {
    margin-bottom: 0px;
  }
  .register-wrapper {
    padding-right: 0px;
    padding-left: 0px;
  }
  .checkbox-block {
    max-width: 115px;
    min-width: 115px;
    padding: 10px;
    font-size: 13px;
    line-height: 20px;
  }
  .onboarding-heading {
    margin-bottom: 12px;
  }
  .onboarding-para {
    max-width: 100%;
    font-size: 16px;
    line-height: 24px;
  }
  .onboarding-para.lg {
    max-width: 100%;
  }
  .onboarding-selection {
    padding: 15px 10px;
  }
  .checkbox-wp {
    margin-right: -5px;
    margin-left: -5px;
  }
  .checkbox-col {
    margin-bottom: 10px;
    padding-right: 5px;
    padding-left: 5px;
  }
  .pricing-box-wp {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .pricing-box {
    max-width: 100%;
    margin-right: 0px;
    margin-bottom: 20px;
  }
  .track-wrapper {
    padding-bottom: 145px;
  }
  .track-item {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .track-time {
    max-width: 50%;
  }
  .btn-cart {
    max-width: 100%;
  }
  .track-info.trans {
    max-width: 100%;
    margin-bottom: 10px;
  }
  .track-btn {
    max-width: 100%;
    margin-top: 12px;
  }
  .track-btn.trans {
    max-width: 50%;
    margin-top: 0px;
  }
}

@font-face {
  font-family: 'Circularstd';
  src: url('../fonts/CircularStd-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Ilisarniq';
  src: url('../fonts/Ilisarniq-Black.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Ilisarniq';
  src: url('../fonts/Ilisarniq-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Ilisarniq';
  src: url('../fonts/Ilisarniq-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Ilisarniq';
  src: url('../fonts/Ilisarniq-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Ilisarniq';
  src: url('../fonts/Ilisarniq-Demi.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}
