/* You can add global styles to this file, and also import other style files */
// @import "assets/scss/_globals.scss";
// @import "assets/scss/style.scss";
@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'assets/css/components.css';
@import 'assets/css/normalize.css';
@import 'assets/css/trip-note-pro.css';
@import 'assets/css/custom.css';
